.hide-on-screen {
    display: none;
  }
  

  .print-only {
    display: none;
  }
  
  
  @media screen {
   
    .print-only {
      display: none;
    }
  }
  
  
  @media print {
   
    body * {
      visibility: hidden;
    }
  
   
    .print-only {
      display: block; 
      visibility: visible; 
      position: relative; 
      color: black; 
      background: transparent !important; 
    }
  
  
    #chartContainer, #chartContainer * {
      visibility: visible;
      display: block; 
    }
  
    
    #chartContainer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
    }
  
   
    #chartContainer canvas {
      width: 100% !important;
      height: auto !important;
    }

    .print-header .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    
      .print-header .col {
        flex: 1;
        display: flex;
        justify-content: center; 
      }
    
      .print-header .col:first-child {
        justify-content: flex-start; 
      }
    
      .print-header .col:last-child {
        justify-content: flex-end; 
      }
    
      .header-logo {
        max-width: 100px; 
        height: auto;
      }
  }
  